<template>
        <div class="form applyform">
            <div class="head-name"><img src="@assets/images/applystore.jpg"/></div>
            <div class="bg-col">
                <div class="dy-text-box xingxing">
                    <div class="title-name" style="    font-size: 0.2rem;">公司名/店面(简称6字内)</div>
                    <input name="title"   v-model="title" value="title" />
                </div>
                <div class="dy-text-box xingxing">
                    <div class="title-name">手机号</div>
                    <input  name="phone" v-model="phone" value="phone" />
                </div>
                <div class="dy-text-box xingxing">
                    <div class="title-name">所在地区</div>
                    <div  @click.stop="show2 = true" style="width: 100%;text-align: right;">{{ model2 || "省，市，区" }}</div>
                    <!-- <input hidden :value="model2" /> -->
                    <CitySelect
                    v-model="show2"
                    :callback="result2"
                    :items="district"
                    provance=""
                    city=""
                    area=""
                    ></CitySelect>
                    <div class="iconfont icon-weizhi font-color-red"></div>
                </div>
                <div class="dy-text-box xingxing">
                    <div class="title-name">店铺或办公地址</div>
                    <textarea v-model="detailed_address"></textarea>
                </div>
                <div class="dy-text-box xingxing">
                    <div class="title-name">营业时间</div>
                    <input name="day_time"  v-model="day_time" value="周一至周日 10:00 AM  结束时间：22:00 PM" />
                </div>
                <!-- <div class="dy-text-box">
                    <div class="title-name">LOGO</div>
                                <VueCoreImageUpload
                                    class="logo-pic"
                                    :crop="false"
                                    compress="80"
                                    @imageuploaded="imageuploaded"
                                    :headers="headers"
                                    :max-file-size="5242880"
                                    :credentials="false"
                                    inputAccept="image/*"
                                    inputOfFile="file"
                                    :url="url"
                                    ref="upImg"
                                
                                >
                                    <img :src="pic" />
                                </VueCoreImageUpload>
                </div> -->
                <div class="dy-text-box xingxing" style="border:unset">
                    <div class="title-name" style="font-size:0.2rem;">上传店面或办公环境照</div>
                    <input placeholder="（最多传9张图）" disabled />
                </div>
                <div class="product_uploader" style="border-bottom: unset;">
                    <Uploader v-model="fileOne" :after-read="afterReads" :before-read="beforeReads" :max-size="10000 * 1024" :max-count="1" @oversize="onOversizes" />
                </div>
                <div class="text">
                    <div>非必填项</div>
                </div>
                <div class="dy-text-box" style="border:unset">
                    <div class="title-name" style="font-size:0.2rem;" >公司或品牌logo</div>
                </div>
                <div class="product_uploader">
                    <Uploader v-model="fileList" :after-read="afterRead" :before-read="beforeRead" :max-size="10000 * 1024" :max-count="9" @oversize="onOversize" />
                </div>
                <div class="dy-text-box">
                    <div class="title-name">企业或店面介绍</div>
                    <textarea v-model="res"></textarea>
                </div>
            </div >
                <div class="btn">
                    <!-- <button class="anniu" @click="dtsave" ><span>保存</span></button> -->
                    <button class="anniu fa" @click="dtsubmit" ><span>{{btnName}}</span></button>
                </div>
        </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { CitySelect } from "vue-ydui/dist/lib.rem/cityselect";
import District from "ydui-district/dist/jd_province_city_area_id";
import { VUE_APP_API_URL } from "@utils";
import VueCoreImageUpload from "vue-core-image-upload";
import { uploadImg,generateStore } from "@api/tasting";
import { Icon, Uploader , Toast } from 'vant';
import { Image as VanImage } from 'vant';

export default {
        name: "store",
        components: {
             Icon, Uploader , Toast ,VanImage ,CitySelect,VueCoreImageUpload
        },
        data: function() {
            return {
                url: `${VUE_APP_API_URL}/upload/image`,
                headers: {
                    "Authori-zation": "Bearer " + this.$store.state.app.token
                },
                pic:'',
                title:'Wine Shop',
                detailed_address:'',
                phone:'',
                day_time:'周一至周日 10:00 AM  结束时间：22:00 PM',

                show2: false,
                model2: "",
                district: District,
                address: {
                    
                },
                res:'',
                message:'',
                id:0,
                fileList:[],
                fileOne:[],
                storeData:[],
                applyCode:'',
                btnName:'立即生成',
                btnUrl:'',
                btnSrore:'',
            };
        },
        created: function() {
            this.applyCode=this.$route.query.applycode;
            console.log(this.fileOne);
        },  
        mounted: function() {
            this.getBusinessData();
        },
        methods: {
            clicknavigateTo: function(url,store_id) {
                wx.miniProgram.navigateTo({url:url+'&store_id='+store_id})
            },
            onFileChange(file){
                console.log(file);return;
            },
            result2(ret) {
                this.model2 = ret.itemName1 + " " + ret.itemName2 + " " + ret.itemName3;
                this.address.province = ret.itemName1;
                this.address.city = ret.itemName2;
                this.address.district = ret.itemName3;
            },

            getBusinessData:function(){
                let List=[];
                List[0] = {'url':'https://store.oupuhui.com/uploads/attach/2020/09/20200928/e83ad86a62616018ea14dce216c16321.jpg'};
                this.fileList=List;
                this.fileOne=List; 
            }, 
            //提交
            dtsubmit(){
                if(this.btnUrl){
                    this.clicknavigateTo(this.btnUrl,this.btnSrore);return;
                }
                if(!this.applyCode||this.applyCode=='default'){
                    Toast.fail({
                        message: '缺少参数',
                        forbidClick: true,
                    });
                    return
                }
                if(!this.title){
                    Toast.fail({
                        message: '请填写店名',
                        forbidClick: true,
                    });
                    return
                }
                if(this.fileOne.length==0){
                    Toast.fail({
                        message: '请上传LOGO',
                        forbidClick: true,
                    });
                    return
                }
                if(!this.address){
                    Toast.fail({
                        message: '请选择地区',
                        forbidClick: true,
                    });
                    return
                }
                // if (!/^1(3|4|5|7|8|9|6)\d{9}$/.test(this.phone)) {
                //     return this.$dialog.toast({ mes: "请填写正确的手机号" });
                // }
                // if(!this.detailed_address){
                //     Toast.fail({
                //         message: '请填写详情地址',
                //         forbidClick: true,
                //     });
                //     return
                // }
                if(this.fileList.length==0){
                    Toast.fail({
                        message: '请上传图片',
                        forbidClick: true,
                    });
                    return
                }
                let imagearr=[];
                this.fileList.forEach(function(item) {
                    imagearr.push(item.url)
                });
                this.pic=this.fileOne[0].url;
                Toast.loading({
                    duration: 0,
                    message: '修改中...',
                    forbidClick: true,
                });
                generateStore({
                    name:this.title,
                    image:this.pic,
                    phone:this.phone,
                    day_time:this.day_time,
                    province:this.address.province,
                    city:this.address.city,
                    district:this.address.district,
                    detailed_address: this.detailed_address, //详情地址
                    slider_image: imagearr, //图片集合
                    applycode:this.applyCode,
                    res:this.res,
                }).then(res => {
                    var resdata = res.data;
                    this.btnName="立即前往微场景";
                    this.btnUrl=resdata.url;
                    this.btnSrore=resdata.store_id;
                    Toast.success({
                        message: resdata.msg,
                        forbidClick: true,
                    });
                    //var that = this;
                    //setTimeout(function(){ 
                     //   that.$router.back(-1);
                    //}, 2000);
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            afterReads(file) {
                // 此时可以自行将文件上传至服务器
                let index = this.fileOne.length - 1;
                file.status = 'uploading';
                file.message = '上传中...';
                var formData = new FormData();
    　　        formData.append('file', file.file);
                Toast.loading({
                    duration: 0,
                    message: '上传中',
                    forbidClick: true,
                });
                uploadImg(formData).then(res => {
                    Toast.clear();
                    this.fileOne[index] = res.data;
                    file.status = '';
                    file.message = '';
                    console.log(this.pic)
                })
                .catch(err => {
                    this.fileOne.splice(index,1);
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            beforeReads(file) {
                if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                    Toast('只允许上传jpg/png格式的图片！');
                    return false;
                }
                return true;
            },
            onOversizes(file) {
                console.log(file);
                Toast('图片大小不能超过 10M');
            },


            //门店图
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                let index = this.fileList.length - 1;
                file.status = 'uploading';
                file.message = '上传中...';
                var formData = new FormData();
    　　        formData.append('file', file.file);
                Toast.loading({
                    duration: 0,
                    message: '上传中',
                    forbidClick: true,
                });
                uploadImg(formData).then(res => {
                    Toast.clear();
                    this.fileList[index] = res.data;
                    file.status = '';
                    file.message = '';
                    console.log(this.fileList)
                })
                .catch(err => {
                    this.fileList.splice(index,1);
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            beforeRead(file) {
                if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                    Toast('只允许上传jpg/png格式的图片！');
                    return false;
                }
                return true;
            },
            onOversize(file) {
                console.log(file);
                Toast('图片大小不能超过 10M');
            },


        }

        



    }
</script>
<style scoped lang="scss">
    ::v-deep{
        .van-icon-star{font-size: 0.5rem!important;}
        .van-uploader__upload, .van-uploader__preview-image{border: 2px solid #eee;border-radius: 0.2rem;}
        .Scenes{
            .van-picker__columns{display:none;}
        }
    }
    .form{
        .head-name{
            //width: 100%;
            img{
                width: 100%;
            }
        }
    }
    .form .bg-col{
        background-color: #fff;
    }
    .dy-text-box{
        width: 95%;
        display: flex;
        padding: 0.3rem;
        font-size: 0.3rem;
        border-bottom: 1px solid #eee;
        margin: 0 auto;
    }
    .dy-text-box .title-name{
        width: 40%;
    }
     .xingxing .title-name:before {
        content: '* ';
        color: red;
     }
     .noxing .title-name:before{
         content: '* ';
         color: #000;
     }
    .dy-text-box input{width: 60%;text-align: right;}
    .dy-text-box textarea{
        height: 2rem;width: 60%;text-align: right;
    }
    .linename{
        display: block;
        
        margin: 0 0.3rem;
    }
    .btn{display: flex;flex-wrap: wrap;height: 1.6rem;}
    .btn .anniu{flex-direction:row;justify-content:space-evenly;margin-top: 0.3rem;width: 100%;}
    .btn .anniu span{background-color: #f00;color: #fff;border-radius: 0.4rem 0.4rem;padding:0.2rem 1.5rem ;}
    .product_uploader{padding:0.3rem;border-bottom: 1px solid #eee;}
    .logo-pic{
        position:relative;
        width: 1.5rem;
        height: 1.5rem;
        right: 6%;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .applyform .text{
        font-size: 0.1rem;
        text-align: center;
        padding: 0.02rem 0;
        background: #e3e3e3;
        margin: 0 0.3rem;
    }
</style>